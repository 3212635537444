.lia-edit-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 36px;
  width: 36px;
  padding: 0;
  background-color: hsla(var(--lia-bs-white-h), var(--lia-bs-white-s), var(--lia-bs-white-l), 0.8);
  box-shadow: var(--lia-box-shadow-xs);
  border-radius: var(--lia-bs-border-radius-sm);
  z-index: 2;
}
